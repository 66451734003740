<template>
	<div>
		<div>
			<a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
				<a-col :span="24" class="mb-24">
					<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ height: '100%' }" style="height: 800px">
						<a-col :span="18" style="height: 100%; background: #f7f7f7">
							<div style="height: 100%; background: #f7f7f7; padding: 20px; margin-bottom: 20px">
								<a-row :gutter="24" type="flex" align="stretch" style="height: 100%">
									<a-col :span="24" class="mb-24">
										<div style="width: 100%; height: 100%; background-color: #fff; padding: 10px">
											<a-list size="large" :pagination="pagination" :data-source="listData" :locale="locale">
												<a-list-item
													class="ant-list-item-no-flex"
													slot="renderItem"
													key="item.title"
													slot-scope="item, index"
													style="position: relative">
													<template slot="actions">
														<span @click.stop="collectWriteItem('like-o', item)">
															<a-icon
																type="like-o"
																style="margin-right: 8px"
																theme="filled"
																:style="{ color: item.like_status == 0 ? '#333' : '#1890ff' }" />
															{{ item.like_num }}
														</span>
													</template>

													<!--   昵称下一行 -->
													<a-list-item-meta :description="item.pc_time_text" style="position: relative">
														<a
															slot="title"
															@click="toRole(item)"
															@onmouseover="title(item)"
															:href="item.href"
															style="font-size: 20px; font-weight: bolder">
															{{ roleFriend.title }}
															<img
																v-if="roleFriend.sex == '女'"
																style="margin-bottom: 3px"
																width="20"
																height="20"
																src="../assets/imgs/woman.png"
																alt="" />
															<img
																v-else
																style="margin-bottom: 3px"
																width="20"
																height="20"
																src="../assets/imgs/man.png"
																alt="" />
														</a>
														<a-avatar slot="avatar" shape="square" :size="60" :src="roleFriend.image" />
													</a-list-item-meta>

													<div
														v-show="roleFriend.reseller"
														style="
															width: 20px;
															height: 20px;
															position: relative;
															top: -20px;
															left: 40px;
															background-color: #1890ff;
															text-align: center;
															line-height: 20px;
															border-radius: 15px 0px 4px 0px;
														">
														<img
															style="width: 16px; height: 16px; margin-left: 3px; border-radius: 10px"
															slot="extra"
															:src="renImage"
															alt="" />
													</div>
													<div
														style="
															position: absolute;
															top: 55px;
															left: 137px;
															font-size: 12px;
															color: #999;
															margin-left: 30px;
														">
														<span slot="extra">
															<span v-if="roleFriend.sex == '女'">她</span><span v-else>他</span>已对话
															{{ roleFriend.usages }} 次
														</span>
													</div>
													<span style="display: inline-block; margin-left: 68px" v-html="item.content"> </span>
													<div class="imgList">
														<div style="display: flex; position: relative" v-for="(i, j) in item.images" :key="j">
															<img
																class="imgItem"
																style="margin-left: 10px; margin-bottom: 20px; height: auto"
																slot="extra"
																width="100"
																height="100"
																alt="logo"
																:src="i"
																@click="showImagePreview(i, item.images)" />
															<span class="imgCover"></span>
														</div>
													</div>
													<div style="width: 104%; height: 12px; background-color: #f7f7f7; margin-left: -2%"></div>
												</a-list-item>
											</a-list>
										</div>
									</a-col>
								</a-row>
							</div>
						</a-col>
						<!-- 侧边栏关注 -->
						<a-col :span="6" style="height: 100%">
							<div class="dialog_box">
								<div class="list">
									<div v-for="item in roleData" :key="item.id">
										<div class="item" :class="{ active: item.id == roleActiveId }" :key="'role_' + item.id">
											<div
												style="
													display: grid;
													grid-template-rows: 100%;
													grid-template-columns: 20% 55% 15%;
													align-items: center;
												">
												<div style="width: 60px; height: 60px; margin-right: 20px; margin-top: 3px; position: relative">
													<!-- <a-avatar shape="circle" :size="60" :src="item.image" /> -->
													<img width="100%" height="100%" style="border-radius: 14px" :src="item.image" alt="" />
													<div
														v-show="item.reseller"
														style="
															width: 20px;
															height: 20px;
															position: absolute;
															top: 40px;
															left: 40px;
															background-color: #1890ff;
															text-align: center;
															line-height: 20px;
															border-radius: 15px 0px 13px 0px;
														">
														<img
															style="width: 16px; height: 16px; margin-left: 3px; border-radius: 10px"
															src="../assets/imgs/renzheng2.png"
															alt="" />
													</div>
												</div>
												<div class="line1 c-line-1">
													{{ item.title }}
													<div
														class="line2 c-line-2"
														style="
															font-weight: normal;
															display: -webkit-box;
															-webkit-box-orient: vertical;
															-webkit-line-clamp: 1;
															overflow: hidden;
															text-overflow: ellipsis;
														">
														{{ item.welcome }}
													</div>
												</div>
												<div style="padding-right: 10px">
													<a-button @click="handleFollow(item)" shape="round" class="follow"> 聊天 </a-button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</a-col>
					</a-card>
				</a-col>
			</a-row>
		</div>
	</div>
</template>
<script>
	import emojiList from "@/utils/emoji";
	import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
	// 表情包
	const chunk = function (arr, num) {
		num = num * 1 || 1;
		var ret = [];
		arr.forEach(function (item, i) {
			if (i % num === 0) {
				ret.push([]);
			}
			ret[ret.length - 1].push(item);
		});
		return ret;
	};
	export default {
		components: {},
		data() {
			return {
				show: false,
				list: [
					{
						name: "名字",
						avater: "",
						Copywriting: "",
						image: "",
						video: "",
						content: "",
					},
				],
				data: [],
				listData: [],
				roleFriend: {},
				renImage: require("@/assets/imgs/renzheng2.png"),
				pagination: {},
				actions: [
					// { type: 'star-o', text: '156' },
					{ type: "like-o", text: "156" },
					{ type: "message", text: "2" },
				],
				currentType: "",
				// 表情包
				emojiGroup: chunk(emojiList, 20), // 表情列表
				emojiList: emojiList,
				emojiShow: false,
				chatCont: "",
				// 朋友圈
				friendPage: {
					page: 1,
					pagesize: 10,
					rid: "",
				},
				locale: {
					emptyText: "暂无数据",
				},
				pre: require("@/assets/imgs/arrow-left.png"),
				next: require("@/assets/imgs/arrow-right.png"),
				del: require("@/assets/imgs/del.png"),
				index: 0,
				div: "",
				renImage: require("@/assets/imgs/renzheng2.png"),

				// 侧边栏关注
				roleData: [],
				roleActiveKey: [],
				roleActiveId: 0,
				roleFirstId: "",
				visible: true,
			};
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"]),
		},
		watch: {
			inputText(newValue, oldValue) {
				this.disabled = newValue == "" ? false : true;
			},
		},
		created() {
			this.friendList3();
			if (this.$store.state.user.userInfo) {
				this.ruleMsg(); // 对话角色
			} else {
				this.second(); //前十
			}
			this.friendPage.rid = this.$route.query.rid;
		},
		mounted() {},
		methods: {
			//
			collectWriteItem(type, item) {
				if (type == "like-o") {
					if (item.like_status == 1) {
						//已经点赞
						this.cancel_like({ id: item.id });
					} else {
						//未点赞
						this.set_like({ id: item.id });
					}
				}
			},
			// 朋友圈 friendList
			friendList3() {
				let obj = {
					page: 1,
					pagesize: 10,
				};
				this.$http("user.ruleList", this.friendPage).then((res) => {
					if (res.code === 1) {
						this.friendPage.pagesize = res.data.total;
						this.friendList();
					} else {
					}
				});
			},
			// 朋友圈
			friendList() {
				this.$http("user.ruleList", this.friendPage).then((res) => {
					if (res.code === 1) {
						console.log("单个朋友圈", res.data.list);
						this.listData = res.data.list; //角色朋友圈
						this.roleFriend = res.data.prompt_data; //角色信息
						res.data.list.forEach((item) => {
							var pattern = /[]/;
							item.content = this.replace_em2(item.content);
						});
						// 这是啥
						// this.listData.map((item) => {
						// 	return (item.renImage = this.renImage);
						// });
					}
				});
			},
			// ruleList
			// 聊天表情转换
			replace_em2(str) {
				str = str.replace(/\[em-([a-z_]*)\]/g, "<i class='em em-$1'/></i>");
				return str;
			},
			// 点赞
			set_like(data) {
				this.$http("user.set_like", data).then((res) => {
					if (res.code === 1) {
						this.$message.success("点赞成功");
						this.friendList3();
					} else {
						this.$message.error("点赞失败");
					}
				});
			},
			// 取消点赞
			cancel_like(data) {
				this.$http("user.cancel_like", data).then((res) => {
					if (res.code === 1) {
						this.$message.success("取消点赞");
						this.friendList3();
					} else {
						this.$message.error("取消失败");
					}
				});
			},
			showImagePreview(i, item) {
				console.log("列表", item);
				const image = new Image();
				image.src = i;
				image.onload = () => {
					// 创建弹出层
					const previewContainer = document.createElement("div");
					this.div = previewContainer;
					previewContainer.style.position = "fixed";
					previewContainer.style.top = 0;
					previewContainer.style.bottom = 0;
					previewContainer.style.left = "14%";
					previewContainer.style.right = 0;
					previewContainer.style.backgroundColor = "rgba(0,0,0,0.6)";
					previewContainer.style.display = "flex";
					previewContainer.style.justifyContent = "center";
					previewContainer.style.alignItems = "center";
					document.body.appendChild(previewContainer);
					// 在弹出层中添加图片
					const previewImage = document.createElement("img");
					previewImage.src = i;
					previewImage.style.maxWidth = "80%";
					previewImage.style.maxHeight = "80%";
					previewContainer.appendChild(previewImage);
					// 在弹出层中添加上一张
					// const pre = document.createElement('img');
					// pre.src = this.pre
					// pre.style.position = 'fixed';
					// pre.style.top = '50%';
					// pre.style.left = '25%';
					// previewContainer.appendChild(pre);
					// pre.addEventListener('click', () => {
					//     this.index--;
					//     if (this.index < 0) {
					//         this.index = item.length - 1;
					//     }
					//     previewImage.src = item[this.index];
					// });
					// 在弹出层中添加下一张
					// const next = document.createElement('img');
					// next.src = this.next
					// next.style.position = 'fixed';
					// next.style.top = '50%';
					// next.style.right = '12%';
					// previewContainer.appendChild(next);
					// next.addEventListener('click', () => {
					//     this.index++;
					//     if (this.index == item.length) {
					//         this.index = 0;
					//     }
					//     previewImage.src = item[this.index];
					// });
					// 在弹出层中添加删除
					// const del = document.createElement('img');
					// del.style.width = '100px'
					// del.style.height = '100px'
					// del.src = this.del
					// del.style.position = 'fixed';
					// del.style.top = '10%';
					// del.style.right = '20%';
					// previewContainer.appendChild(del);
					// 点击删除，关闭预览
					previewContainer.addEventListener("click", () => {
						document.body.removeChild(previewContainer);
					});
				};
			},

			toRole(item) {
				// 审核成功，可进入聊天
				if (item) {
					this.$router.push("/role/chat?id=" + item.id);
				}
			},
			// 对话角色
			ruleMsg() {
				this.$http("user.ruleMsg").then((res) => {
					if (res.code === 1) {
						console.log(res.data);
						res.data.length > 0 ? (this.roleData = res.data) : this.second();
					}
				});
			},
			// 聊天前十
			second() {
				this.$http("role.second", { topic_id: 0 }).then((res) => {
					if (res.code === 1) {
						// 排序
						res.data = res.data.sort(function (a, b) {
							return b.usages - a.usages;
						});
						this.roleData = res.data.slice(0, 10);
					}
				});
			},

			tile(item) {
				if (item) {
				}
			},
			// 聊天
			handleFollow(item) {
				this.$router.push("/role/chat?id=" + item.id);
			},
		},
		destroyed() {
			if (document.body.contains(this.div)) {
				document.body.removeChild(this.div);
			}
		},
	};
</script>
<style lang="scss" scoped>
	@import url("../scss/emoji-awesome/css/google.min.css");

	.footer_box {
		padding: 12px;
		box-shadow: 0 1px 10px 2px rgba(0, 0, 0, 0.05);
		border-radius: 8px;
		color: #000;
		cursor: pointer;
		position: relative;
		border: 1px solid transparent;
		margin-right: 10px;
		padding-bottom: 1px;

		&:hover {
			border-color: #1890ff;
			box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
		}

		.icon {
			// width: 320px;
			width: 100%;
			height: 75px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 15px;
			overflow: hidden;

			svg path {
				fill: #1890ff;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		h6 {
			color: #000;
			text-align: center;
		}

		p {
			margin-bottom: 0;
			line-height: 20px;
			height: 30px;
		}

		.collect {
			position: absolute;
			top: 134px;
			right: 35px;
		}
	}

	.imgList {
		display: flex;
		justify-content: start;
		align-content: center;
		margin-top: 5px;
		margin-left: 56px;
		// position: relative;

		.imgItem {
			cursor: crosshair;
		}

		// .imgCover {
		//     position: absolute;
		//     top: 0;
		//     left: 10px;
		//     width: 100px;
		//     height: 100px;
		//     background-color: rgba(0, 0, 0, 0.5);
		//     opacity: 0;
		//     // visibility: hidden;
		// }

		.imgCover:hover {
			// opacity: 1;
			// visibility: visible;
		}
	}

	.ant-list-split .ant-list-item {
		border-bottom: 1px solid #fff;
	}

	.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
		border-bottom: 1px solid #fff;
	}

	::v-deep.ant-list-item-meta-content {
		margin-top: 8px;
	}

	.dialog_box {
		height: 0;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		border-radius: 12px;
		background: #f7f7f7;
		padding: 12px 0;

		.list {
			flex: 1;
			height: 100%;
			padding: 12px;
			overflow: hidden;
			padding-top: 0;

			&:hover {
				overflow-y: scroll;
				overflow-x: hidden;
				padding-right: 0;
			}

			&::-webkit-scrollbar {
				width: 12px;
			}

			&::-webkit-scrollbar-thumb {
				border-radius: 12px;
				border: 4px solid rgba(0, 0, 0, 0);
				box-shadow: 4px 0 0 #a5adb7 inset;
			}

			&::-webkit-scrollbar-thumb:hover {
				box-shadow: 4px 0 0 #4a4a4a inset;
			}

			// 滚动条两端按钮
			&::-webkit-scrollbar-button {
				height: 10px;
			}

			.item {
				padding: 12px;
				background: #fff;
				border-radius: 10px;
				margin-bottom: 12px;
				// cursor: pointer;
				position: relative;

				&:last-child {
					margin-bottom: 5px;
				}

				&.active {
					border: 1px solid #1890ff;
					box-shadow: 1px 1px 10px 0 rgba(#1890ff, 0.2);
				}

				.line1 {
					color: #000;
					font-weight: 700;
				}

				.line2 {
					margin-top: 4px;
					color: #999;
				}
			}
		}
	}

	// 关注
	.follow {
		color: #40a9ff;
		line-height: 25px;
	}

	// .ant-list-split .ant-list-item[data-v-7e6025eb] {
	//     border-bottom: 10px solid #f7f7f7;
	// }

	::v-deep .ant-list-item-action {
		margin-top: -36px !important;
	}

	// ::v-deep .ant-list-split .ant-list-item {
	//     border-radius: 50px !important;
	// }

	// ::v-deep .ant-list-item-meta-description {
	//     position: relative !important;
	// }
</style>
